@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --left-offset: 0;
  --border-radius: 0rem;
  --rotate: 0deg;
}

@media (max-width: 768px) {
  :root {
    --left-offset: -5vw;
    --border-radius: 0vw;
  }
}
.technologies-content {
  position: relative;
  color: #fefefe;
  // padding-top: 5rem;
  // padding-bottom: 8rem;
  width: 100%;
  overflow: hidden;
  .technologies {
    width: calc(100vw - var(--left-offset));
    margin-left: auto;
    &::before {
      content: "";
      // background: linear-gradient(300deg, #0284c7 0, #4fc8fd 100%);
      position: absolute;
      top: 41px;
      border-radius: var(--border-radius);
      -webkit-transform: rotate(var(--rotate));
      -ms-transform: rotate(var(--rotate));
      transform: rotate(var(--rotate));
      z-index: -1;
      right: -20rem;
      bottom: 5rem;
      left: var(--left-offset);
    }
  }
}
